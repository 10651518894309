import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import classNames from "classnames";
import "react-responsive-modal/styles.css";
import AvailableSoon from "@components/Features/AvailableSoon/AvailableSoon";
import { usePathname } from "next/navigation";
import { isPrivateRoute } from "@components/Features/Auth/AuthCheck";
import { AppState } from "@app/store/store";
import { mapLoaded, setIsSoonVisible, setMinHeight } from "@app/store/layoutSlice";
import { ToastContainer } from "react-toastify";
import Script from "next/script";
import Modal from "react-responsive-modal";
import Modals from "@components/Features/Modals/Modals";
import { TemplateProps } from "@app/Template/TemplateProps";
import { Header } from "@widgets";
import { Footer } from "@widgets";
import { footerClasses, headerClasses } from "@widgets";
import { ScrollButton } from "src/widgets/ScrollButton";
import { TechnicalPause } from "@features";
import { CookieConsent } from "@features/cookie-consent/CookieConsent";
import CallbackForm from "@features/callback-form/callback-form-button";
import classes from "./Template.module.scss";

const Template: React.FC<React.PropsWithChildren<TemplateProps>> = ({ children, bgColor }) => {
    const dispatch = useDispatch<any>();

    const isSoonVisible = useSelector((state: AppState) => state.layout.isSoonVisible);
    const isOnTechnicalPause = useSelector((state: AppState) => state.layout.technicalPause);
    const pathname = usePathname();
    const isPrivate = isPrivateRoute(pathname || "");

    const [minContentHeight, setMinContentHeight] = useState(0);
    const footerVisible = useSelector((state: AppState) => state.layout.footerVisible);
    const containerClasses = classNames(classes.Container, {
        [classes.Container__blue]: bgColor === "blue",
        [classes.Container__white]: bgColor === "white"
    });

    const callbackFormVisible = useSelector((state: AppState) => state.layout.callbackFormVisible);

    const calculateHeights = () => {
        const headerEl: HTMLElement | null = document.querySelector(`.${headerClasses.Header}`);
        const footerEl: HTMLElement | null = document.querySelector(`.${footerClasses.Footer}`);
        const windowHeight = window.innerHeight;

        if (headerEl && (footerEl || !footerVisible)) {
            const newMinContentHeight = footerVisible
                ? windowHeight - headerEl.offsetHeight - (footerEl?.offsetHeight || 0)
                : windowHeight - headerEl.offsetHeight;
            setMinContentHeight(newMinContentHeight);
            dispatch(setMinHeight(newMinContentHeight));
        }
    };

    const [isClient, setIsClient] = useState(false);
    React.useEffect(() => {
        if (typeof window !== "undefined") {
            setIsClient(true);
        } else {
            setIsClient(false);
        }
    }, []);

    React.useEffect(() => {
        calculateHeights();
    }, [footerVisible]);

    React.useEffect(() => {
        window.addEventListener("resize", calculateHeights, true);

        return () => {
            window.removeEventListener("resize", calculateHeights);
        };
    }, [footerVisible]);

    return (
        <main className={`${classes.Template}`}>
            <Modals />
            {isSoonVisible && (
                <Modal
                    styles={{
                        overlay: {
                            backdropFilter: isPrivate ? "blur(5px)" : undefined,
                            WebkitBackdropFilter: isPrivate ? "blur(5px)" : undefined
                        },
                        modal: {
                            padding: 0,
                            margin: 0,
                            background: "transparent",
                            width: "100%",
                            maxWidth: 500
                        }
                    }}
                    open={isSoonVisible}
                    onClose={() => {
                        dispatch(setIsSoonVisible(false));
                    }}
                    center
                    showCloseIcon={false}
                >
                    <AvailableSoon />
                </Modal>
            )}

            <Script
                src="https://api-maps.yandex.ru/2.1/?apikey=8cec1c13-67cf-4731-b7d4-746a7ea1bb71&lang=ru_RU"
                strategy="afterInteractive"
                type="text/javascript"
                onLoad={() => {
                    dispatch(mapLoaded());
                }}
            />

            <Header />
            <div
                className={containerClasses}
                style={{
                    minHeight: minContentHeight || 1000
                }}
            >
                {isOnTechnicalPause ? (
                    <TechnicalPause minContentHeight={minContentHeight} />
                ) : (
                    children
                )}
            </div>

            {footerVisible && <Footer />}

            {isClient && (
                <ToastContainer
                    position="bottom-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            )}

            {!callbackFormVisible && <CallbackForm />}
            <ScrollButton />
            <CookieConsent />
        </main>
    );
};

export default Template;